if (document.getElementById('date')) { 
    const dateContent = document.getElementById('date')
    const d = new Date()
    d.setDate(d.getDate() - 3)
    const day = d.getDate()
    const month = d.getMonth()
    const year = d.getFullYear()
    const dayName = d.getDay()
    const weekDays = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato']
    const months = [
        'gennaio',
        'febbraio',
        'marzo',
        'aprile',
        'maggio',
        'giugnio',
        'luglio',
        'agosto',
        'settembre',
        'ottobre',
        'novembre',
        'dicembre'
    ]
    const monthName = months[month]
    const weekDayName = weekDays[dayName]
    const dayOutput = `${weekDayName}, ${day} ${monthName} ${year}`
    dateContent.innerHTML = dayOutput
}