// const testerAnfrage = (url) => { 
//     if (window.innerWidth < 600) {
//         window.location.href = url
//     } else {
//         const loader = document.getElementById('loader')
//         const randomDuration = Math.round(Math.random() * (3000 - 1000 + 1) + 1000)
//         loader.classList.add('loading')
//         setTimeout(() => { window.location.href = url }, randomDuration)
//     }    
// }

const testerAnfrage = (url) => { 
    const loader = document.getElementById('loader')
    const loaderSpinner = document.querySelector('.loader')
    const found = document.querySelector('.found')
    const randomDuration = Math.round(Math.random() * (3000 - 1000 + 1) + 1000)
    const layerTarget = document.getElementById('layer__target')
    loader.classList.add('loading')

    layerTarget.href = url


    setTimeout(() => {
        if (window.innerWidth < 840) {
            window.location.href = url
        } else { 
            setTimeout(() => { 
                loaderSpinner.style.display = 'none'
                found.classList.add('active')

            }, randomDuration)
        }

    }, randomDuration)
    
}

const closetester = () => { 
	const loader = document.getElementById('loader')
	const loaderSpinner = document.querySelector('.loader')
	const found = document.querySelector('.found')

	loader.classList.remove('loading')
	loaderSpinner.style.display = ''
	found.classList.remove('active')
}


//Loader Overlay
const openLoader = (text, extra) => {
	const loader = document.createElement('div')
	loader.id = "loader"
	loader.className = "loading"

	let kk = text ? `Stiamo verificando se c’è ancora un posto libero per il test gratuito a ${text}` : 'Stiamo verificando se c’è ancora un posto libero per il test gratuito.'

	loader.innerHTML = `<h3>${kk}</h3><div class="spinner__loader"></div>`
	const searchParams = window.location.search ? window.location.search : ''
	const divider = !searchParams ? '?' : '&';
	const params = extra ? `${divider}kanton=${extra}` : ''
	const kanton = text ? `${divider}kanton=${text}` : ''
	const search = searchParams + params + kanton

	document.body.appendChild(loader)

	setTimeout(() => {
		window.location.href = `/costo-zero/tester${search}`
	}, 2500)
 }